//EN LIGNE

export const PRESTA_URL = 'https://www.patxama.com'
export const API_URL = 'https://appapi.patxama.com'
export const FRONT_URL = 'https://app.patxama.com'

//DEV

//PRESTA:TEST API:LOCAL
/* export const PRESTA_URL = 'https://test.patxama.com'
export const API_URL = 'https://192.168.1.24:5001'
export const FRONT_URL = 'https://192.168.1.24:3000' */

//PRESTA:LIVE API:LIVE
/* export const PRESTA_URL = 'https://www.patxama.com'
export const API_URL = 'https://appapi.patxama.com'
export const FRONT_URL = 'https://192.168.1.24:3000' */

//PRESTA:LOCAL API:LOCAL
/* export const PRESTA_URL = 'https://192.168.1.24/PSP'
export const API_URL = 'https://192.168.1.24:5001'
export const FRONT_URL = 'https://192.168.1.24:3000' */

//PRESTA:LIVE API:LOCAL
/* export const PRESTA_URL = 'https://www.patxama.com'
export const API_URL = 'https://192.168.1.24:5001'
export const FRONT_URL = 'https://192.168.1.24:3000' */
