import {
  GET_MONTH_INVOICES,
  GET_INVOICE,
  CREATE_HENRRI_INVOICE,
  DL_MONTH_INVOICES,
  MAIL_INVOICE,
  DELETE_HENRRI_INVOICE,
  RECALCULATE_NUMBERS,
} from '../constants/actionTypes'
const accountingReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MONTH_INVOICES:
      return {
        ...state,
        [action.payload.month]: {
          ...state[action.payload.month],
          invoices: action.payload.invoices,
          orders: action.payload.orders,
          payments: action.payload.payments,
          superInvoices: action.payload.superInvoices,
          customers: action.payload.customers,
          proHistories: action.payload.proHistories,
        },
      }
    case GET_INVOICE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoice.id]: action.payload,
        },
      }
    case CREATE_HENRRI_INVOICE:
      return {
        ...state,
      }
    case DL_MONTH_INVOICES:
      return {
        ...state,
        [action.payload.interval]: action.payload.invoices,
      }
    case MAIL_INVOICE:
      return {
        ...state,
      }
    case DELETE_HENRRI_INVOICE:
      const targetMonth = state[action.payload.targetMonth]
      console.log(action.payload.targetMonth)
      if (targetMonth) {
        const newSuperInvoices = targetMonth.superInvoices.filter(
          (superInvoice) =>
            String(superInvoice.id) !== action.payload.idSuperInvoice
        )
        const newInvoices = targetMonth.invoices.filter(
          (invoice) => String(invoice.id) !== action.payload.idInvoice
        )
        return {
          ...state,
          [action.payload.targetMonth]: {
            ...state[action.payload.targetMonth],
            superInvoices: newSuperInvoices,
            invoices: newInvoices,
          },
        }
      } else {
        return {
          ...state,
        }
      }
    case RECALCULATE_NUMBERS: {
      console.log('test')
      return { ...state }
    }
    default:
      return state
  }
}

export default accountingReducer
